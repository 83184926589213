import { QuickReplace } from "static/js/app/utils/QuickReplace";
import { Page } from "static/js/app/modules/page";
import { SearchPager } from "static/js/app/modules/searchPager";
import "slick-carousel";
import * as Api from "static/js/app/api/endpoints";
import VehicleStringFormatting from "static/js/app/utils/stringFormatting/vehicleStringFormatting";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import DOMPurify from "dompurify";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import { search } from "static/js/app/api/vehicles";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import { VehicleComparison } from "static/js/app/modules/vehicleComparison";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import VehicleFinanceChecker from "themes/module_finance_plugin/static/js/vehice_finance_checker";

import Common from "static/js/app/pages/common";

export default class WebsectionGallery {
    private static sortTerms: { fieldName: string, isDescending: boolean }[];

    private static pageNumber: number;
    private static itemsPerPage: number;
    private static sortBy: string;
    private static order: string;
    private static financeSearchTerms: Models.FinanceSearchTerms;

    public static async init(siteConfig: SiteConfig, pageConfig: PageConfig, onTemplatesLoaded: () => void = null, vehicleStatus: Models.VehicleStatus = "used", availability: Models.Availability = "available") {
        const websectionSlugifiedName = document.location.pathname.split('/').filter(s=>s.length > 0).pop();

        if(websectionSlugifiedName == "") {
            return;
        }

        WebsectionGallery.order = Page.queryString["order"] || siteConfig.searchConfig.sort_order;
        WebsectionGallery.pageNumber = (parseInt(Page.queryString["page"]) || 1);
        WebsectionGallery.itemsPerPage = (parseInt(Page.queryString["items-per-page"]) || siteConfig.searchConfig.items_per_page);
        WebsectionGallery.sortBy = Page.queryString["sort-by"] || siteConfig.searchConfig.sort_by;
        WebsectionGallery.sortTerms = WebsectionGallery.getSortTerms(WebsectionGallery.sortBy, WebsectionGallery.order);
        WebsectionGallery.financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
        const searchTerms = [ new Models.SearchTerm("websection", websectionSlugifiedName) ];

        let searchCriteria = new Models.SearchCriteria(
            searchTerms, null, WebsectionGallery.sortTerms,
            WebsectionGallery.pageNumber, WebsectionGallery.itemsPerPage, availability, vehicleStatus, WebsectionGallery.financeSearchTerms);

        const searchResults = await Api.Vehicles.search(searchCriteria);

        Page.ready(() => {
            WebsectionGallery.synchronizeSearchInputs();
            VehicleFinanceQuotes.setFinanceSearchTerms(WebsectionGallery.financeSearchTerms);
            WebsectionGallery.setSortByOptions(WebsectionGallery.sortBy, WebsectionGallery.itemsPerPage, WebsectionGallery.order);

            Breadcrumbs.setBreadcrumbs(Breadcrumbs.getGalleryBreadcrumbs(document.location.pathname, pageConfig.title));

            WebsectionGallery.generateSearchResultsHtml(searchResults, "/used/", siteConfig.dealerBranches, siteConfig.financeConfig);

            if (onTemplatesLoaded != null) {
                onTemplatesLoaded();
            }

            WebsectionGallery.initializePager(
                searchResults.totalPages,
                WebsectionGallery.pageNumber,
                WebsectionGallery.itemsPerPage,
                `${window.location.protocol}//${window.location.host}`
            );
        });
    }

    private static initializePager(totalPages: number, pageNumber: number, pageSize: number, baseUrl: string) {
        var els = $('.search-pager').find('.pager');

        if (els.length > 0) {
            var unpagedUrl = document.location.pathname;

            for (var i = 0; i < els.length; i++) {
                SearchPager.init(els[i] as HTMLUListElement, pageNumber, totalPages, pageSize, 9, baseUrl, unpagedUrl);
            }
        }
    }

    private static setSortByOptions(sortBy: string, itemsPerPage: number, order: string) {
        let searchFiltersEl = $('#searchFilters');
        let sortBySelectEl: JQuery<HTMLElement> = searchFiltersEl.find('select[name="sort-by"] option');
        let itemsPerPageSelectEl: JQuery<HTMLElement> = searchFiltersEl.find('select[name="items-per-page"] option');
        let orderSelectEl: JQuery<HTMLElement> = searchFiltersEl.find('select[name="order"] option');

        var optionsAndSelectedValues = [
            { options: sortBySelectEl, selectedValue: sortBy },
            { options: itemsPerPageSelectEl, selectedValue: itemsPerPage },
            { options: orderSelectEl, selectedValue: order }
        ];

        for (var i = 0; i < optionsAndSelectedValues.length; i++) {
            var options = optionsAndSelectedValues[i].options;
            var selectedValue = optionsAndSelectedValues[i].selectedValue;

            if (selectedValue != undefined && selectedValue !== null) {
                for (var oi = 0; oi < options.length; oi++) {
                    let optionEl = options[oi] as HTMLOptionElement;
                    if (selectedValue == optionEl.value) {
                        optionEl.selected = true;
                    }
                }
            }
        }
    }

    private static synchronizeSearchInputs() {
        $('#searchFilters').on("submit", function () {
            var searchFiltersForm = $('#searchFilters');
            var searchBoxItems = $('#searchBox select');

            for (var i = 0; i < searchBoxItems.length; i++) {
                var searchBoxItem = searchBoxItems[i] as HTMLSelectElement;
                var searchEl = '<input type="hidden" name="' + searchBoxItem.name + '" value="' + searchBoxItem.value + '" />';
                searchFiltersForm.append(searchEl);
            }

            return true;
        });
    }

    private static numberWithCommas(x: number) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    private static getSortTerms(sortBy: string, order: string): { fieldName: string, isDescending: boolean }[] {
        let descending = (order === 'desc');

        return [
             { fieldName: sortBy, isDescending: descending },
             { fieldName: 'sold_date', isDescending: descending }
        ];
    }


    private static generateSearchResultsHtml (searchResults: Models.VehicleSearchResult, rootSearchUrl: string, dealerBranches: DealerBranchPublicInfo[], financeConfig: FinanceConfig) {
        var items = searchResults.results;

        // Cache of the template
        var template = document.getElementById("searchResultsTemplate");

        // Get the contents of the template
        var templateHtml = template.innerHTML;
        // Final HTML variable as empty string
        var listHtml = "";

        if(items.length == 0) {
            document.getElementById("searchResults").innerHTML = document.getElementById("noStockTemplate")?.innerHTML ?? "";
            return;
        }

        // regex creation is reasonably expensive, cache the regex built for repeated templates like this
        var cachedRegEx: RegExp = null;
        var regExCacher = function (regex: RegExp) {
            // return the cachced regex if no regex is supplied, or cache it and return it if it is supplied
            if (regex !== null) {
                cachedRegEx = regex;
            }
            return cachedRegEx;
        };

        if(items.length > 0) {
            // Loop through items, replace placeholder tags
            // with actual data, and generate final HTML
            for (var i = 0; i < items.length; i++) {
                var vehicle = items[i];

                const thumbs = WebsectionGallery.getThumbs(vehicle);
                const isSaved = WebsectionGallery.checkIfSaved(vehicle.id);
                let saveEmClass = (isSaved) ? "fas" : "far";
                let saveSectionExtraClasses = (isSaved) ? " saved-button success" : "";
                let saveText = (isSaved) ? "Saved" : "Save";
                var save: string = `<section class="compare-button button carlist${saveSectionExtraClasses}" data-vehId="${vehicle.id}"><i class="${saveEmClass} fa-star" aria-hidden="true"></i><span> ${saveText}</span></section>`;
                var saveMobile: string = `<section class="compare-button button mobile-only carlist${saveSectionExtraClasses}" data-vehId="${vehicle.id}"><i class="${saveEmClass} fa-star" aria-hidden="true"></i><span></span></section>`;

                var socialContent = `${vehicle.year_built} ${vehicle.manufacturer_display} ${vehicle.model_display} ${StringFormatting.coerceNullishToBlank(vehicle.derivative_display)}, ${VehicleStringFormatting.price(vehicle.price, vehicle.sold)} ${window.location.protocol}//${ window.location.hostname}${vehicle.urlPath}`

                var description: string = (vehicle.description !== null && vehicle.description !== 'None' && typeof vehicle.description !== 'undefined')
                    ? (vehicle.description.length < 251)
                        ? "<div class=\"l-node-used-vehicle--search-result__additional\">" + vehicle.description + "</div>"
                        : "<div class=\"l-node-used-vehicle--search-result__additional\">" + vehicle.description.substring(0, 250) + "...</div>"
                    : "";

                var youtube: string = (vehicle.youtube_link !== null && vehicle.youtube_link !== 'None' && typeof vehicle.youtube_link !== 'undefined')
                ? '<i class="fas fa-video"></i> 1'
                : "";

                var vehicleTypeForSearch = (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() + 's' : '';

                // string replacements to perform
                var fuelTypeSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); fuelTypeSearch.fuelType = vehicle.fuel_type;
                var bodyTypeSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); bodyTypeSearch.bodyType = vehicle.body_type;
                var transmissionSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); transmissionSearch.gearboxType = vehicle.transmission;
                var manufacturerSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); manufacturerSearch.make = vehicle.manufacturer;
                var modelSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); modelSearch.make = vehicle.manufacturer; modelSearch.model = vehicle.model;
                var colourSearch = new Models.VehicleSearch(rootSearchUrl, vehicleTypeForSearch); colourSearch.colour = vehicle.basic_colour;
                var replacements = {
                    '%vehicle_id%': vehicle.id,
                    '%vehicle_year%': StringFormatting.coerceNullishToBlank(vehicle.year_built),
                    '%vehicle_type%': (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() : '',
                    '%vehicle_type_plural%': (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() + 's' : '',
                    '%vehicle_make%': vehicle.manufacturer,
                    '%vehicle_make_display%': vehicle.manufacturer_display,
                    '%vehicle_model%': vehicle.model,
                    '%vehicle_model_display%': vehicle.model_display,
                    '%vehicle_derivative%': StringFormatting.coerceNullishToBlank(vehicle.derivative),
                    '%vehicle_derivative_display%': StringFormatting.coerceNullishToBlank(vehicle.derivative_display),
                    '%vehicle_price%': VehicleStringFormatting.price(vehicle.price, vehicle.sold),
                    '%vehicle_list_price%': VehicleStringFormatting.listPrice(vehicle.list_price, vehicle.sold, ""),
                    '%vehicle_discount_amount%': VehicleStringFormatting.savingPrice(vehicle.discount_amount, vehicle.sold, ""),
                    '%vehicle_discount_price%': VehicleStringFormatting.price(vehicle.discount_price, vehicle.sold, ""),
                    '%vehicle_photocount%': vehicle.imageCount.toString(),
                    '%vehicle_photo%': vehicle.main_image?.i800x600 ?? WebsectionGallery.getPlaceholder(vehicle.vehicle_type),
                    '%vehicle_thumbs%': thumbs,
                    '%vehicle_mileage%': (vehicle.mileage !== null) ? WebsectionGallery.numberWithCommas(vehicle.mileage) : '',
                    '%vehicle_engine%': StringFormatting.coerceNullishToBlank(vehicle.engine),
                    '%vehicle_engine_link%': '<a href="' + Page.getSearchUrl(fuelTypeSearch, true) + '">' + vehicle.engine + '</a>',
                    '%vehicle_gearbox%': StringFormatting.coerceNullishToBlank(vehicle.gearbox),
                    '%vehicle_gearbox_link%': '<a href="' + Page.getSearchUrl(transmissionSearch, true) + '">' + vehicle.gearbox + '</a>',
                    '%vehicle_capacity%': VehicleStringFormatting.engineSize(vehicle.engine_capacity),
                    '%vehicle_insurance%': StringFormatting.coerceNullishToBlank(vehicle.insurance_group),
                    '%vehicle_mpg%': VehicleStringFormatting.mpg(vehicle.mpg),
                    '%vehicle_length%': StringFormatting.coerceNullishToBlank(vehicle.length),
                    '%vehicle_unladen_weight%': StringFormatting.coerceNullishToBlank(vehicle.unladened_weight),
                    '%vehicle_co2%': VehicleStringFormatting.co2(vehicle.co2),
                    '%vehicle_tax%': VehicleStringFormatting.taxRate(vehicle.tax_rate_12),
                    '%vehicle_branch%': StringFormatting.coerceNullishToBlank(vehicle.branch_name),
                    '%vehicle_branch_details_custom%': VehicleStringFormatting.generateVehicleBranchDetailsCustomHtml(dealerBranches.filter(b=>b.id == vehicle.branch_id).pop()),
                    '%vehicle_additional%': description,
                    '%vehicle_make_link%': '<a href="' + Page.getSearchUrl(manufacturerSearch, true) + '">' + vehicle.manufacturer_display + '</a>',
                    '%vehicle_model_link%': '<a href="' + Page.getSearchUrl(modelSearch, true) + '">' + vehicle.model_display + '</a>',
                    '%vehicle_sash%': VehicleStringFormatting.sash(vehicle.vehicle_sash),
                    '%vehicle_save%': save,
                    '%vehicle_whatsapp%': encodeURIComponent(socialContent),
                    '%vehicle_twitter%' : encodeURIComponent(socialContent),
                    '%vehicle_youtube%': youtube,
                    '%vehicle_save_mobile%': saveMobile,
                    '%vehicle_vat_exempt%': VehicleStringFormatting.vatExempt(vehicle.vat_exempt),
                    '%vehicle_vat_excluded%': VehicleStringFormatting.vatExcluded(vehicle.vat_excluded),
                    '%vehicle_height%' : VehicleStringFormatting.millimeters(vehicle.height),
                    '%vehicle_internal_length%' : VehicleStringFormatting.millimeters(vehicle.internal_length),
                    '%vehicle_berth%' : StringFormatting.coerceNullishToBlank(vehicle.berth),
                    '%vehicle_unladened_weight%': VehicleStringFormatting.kilogrammes(vehicle.unladened_weight),
                    '%vehicle_mtplm%' : VehicleStringFormatting.kilogrammes(vehicle.mtplm),
                    '%vehicle_category%': StringFormatting.coerceNullishToBlank(vehicle.category),
                    '%vehicle_seats%': StringFormatting.coerceNullishToBlank(vehicle.seats),
                    '%vehicle_exhaust_manufacturer%': StringFormatting.coerceNullishToBlank(vehicle.exhaust_manufacturer),
                    '%vehicle_muffler%': StringFormatting.coerceNullishToBlank(vehicle.muffler),
                    '%vehicle_body%': StringFormatting.coerceNullishToBlank(vehicle.body_name),
                    '%vehicle_body_link%': StringFormatting.coerceNullishToBlank(vehicle.body_name, `<a href="${Page.getSearchUrl(bodyTypeSearch, true)}">${vehicle.body_name}</a>`),
                    '%vehicle_finance_quotes%' : VehicleFinanceQuotes.setVehicleFinanceQuotes(vehicle.finance_quotes),
                    "%vehicle_details_url%": vehicle.urlPath,
                    '%vehicle_monthly_payment_link%': VehicleFinanceQuotes.setVehicleMonthlyPayment(vehicle),
                    "%vehicle_finance_checker_url%": VehicleFinanceChecker.GetFinanceCheckerUrl(financeConfig,vehicle),
                    '%vehicle_colour%': StringFormatting.coerceNullishToBlank(vehicle.colour),
                    "%vehicle_vrm%": StringFormatting.coerceNullishToBlank(vehicle.vrm),
                    "%vehicle_mot_expiry%":  StringFormatting.dateToString(vehicle.mot_expiry),
                    "%vehicle_colour_link%": '<a href="' + Page.getSearchUrl(colourSearch, true) + '">' + vehicle.colour + '</a>'


                };

                // do replacements (using regex caching)
                listHtml += QuickReplace.quickReplace(replacements, templateHtml, regExCacher);
            }

            // create document fragment so we can adjust using DOM before visibly rendering
            const dummyEl = document.createElement("span");
            dummyEl.innerHTML = DOMPurify.sanitize(listHtml);

            // remove empty overview list items
            WebsectionGallery.removeEmptySpecListEls(dummyEl.querySelectorAll(".specs-list"));

            // Replace the HTML of #searchResults with final HTML
            document.getElementById("searchResults").innerHTML = dummyEl.innerHTML;
            let  vehicleWithFinance = items.filter(f=> f.finance_quotes != null && f.finance_quotes !=null && f.finance_quotes.length >0)[0];
            if (vehicleWithFinance)
            {
                let finance_representative_example_div = document.getElementById("finance_representative_example");
                if (finance_representative_example_div)
                {
                    finance_representative_example_div.innerHTML = DOMPurify.sanitize(VehicleFinanceQuotes.setVehicleFinanceQuotes(vehicleWithFinance.finance_quotes),{ADD_ATTR: ['target']});
                }
            }
            $(".compare-button").on("click", (evt) => {
                evt.preventDefault();
                let btnEl = (evt.target.classList.contains("compare-button")) ? evt.target : $(evt.target).parents(".compare-button:first")[0]
                const vehId = btnEl.getAttribute("data-vehId");
                const error = VehicleComparison.toggleVehicleCompare(vehId);
                if (error != null) {
                    console.log(error);
                }
                Common.updateSavedVehiclesCount();

                WebsectionGallery.toggleVehicleCompareButtonState(WebsectionGallery.checkIfSaved(vehId), btnEl);
            });
        }
    }

    private static checkIfSaved(vehicleID: string) {
        return VehicleComparison.isVehicleInComparisonList(vehicleID);
    }

    private static toggleVehicleCompareButtonState(isSaved: boolean, compareBtnEl: HTMLElement) {
        const isMobileView = compareBtnEl.classList.contains("mobile-only");

        let saveSectionExtraClasses = "saved-button success";
        if (isSaved) {
            $(compareBtnEl)
                .addClass(saveSectionExtraClasses)
                .children("i").addClass("fas").removeClass("far");

            if (!isMobileView) {
                $(compareBtnEl).children("span")[0].textContent = " Saved";
            }
        } else {
            $(compareBtnEl)
                .removeClass(saveSectionExtraClasses)
                .children("i").addClass("far").removeClass("fas");

            if (!isMobileView) {
                $(compareBtnEl).children("span")[0].textContent = " Save";
            }
        }
    }

    private static removeEmptySpecListEls(specListEls: NodeListOf<Element>) {
        if(specListEls != null) {
            [].forEach.call(specListEls,(specListEl: Element)=>
            {
            let blankEls: Element[] = [];
            [].forEach.call(specListEl.children, (specEl: Element) => {
                const valEls = specEl.getElementsByClassName("field-value");
                if(valEls.length) {
                    if(valEls[0].innerHTML == "") {
                        blankEls.push(specEl);
                    }
                }
            });

            [].forEach.call(blankEls, (el: HTMLElement) => { specListEl.removeChild(el); });
            });
        }
    }

    private static getPlaceholder(vehicleType: string) {
        switch (vehicleType) {
          case 'bike':
            return "/basemedia/bike.png";
          break;
          case 'caravan':
            return "/basemedia/caravan.png";
          break;
          case 'van':
            return "/basemedia/commercial.png";
          break;
          case 'motorhome':
            return "/basemedia/motorhome.png";
          break;
          default:
            return "/basemedia/car.png";
        }
      }

      private static getThumbs(vehicle: Models.Vehicle) {
          if (vehicle.images.length == 0) {
              return "";
          }
          else {
            let thumbs = '<ul class="vehicle-results-thumbnails">';
            for (let i = 1; i< ( vehicle.images.length >=7 ? 7:  vehicle.images.length); i++) {
              thumbs += `<li><img src="${vehicle.images[i].i320x240}" class=""></li>`;
            }
            thumbs += '</ul>';
            return thumbs;
          }
      }
}
